import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TetherRoot } from './TetherRoot';
import { TetherAngularModule, TetherManagerService } from '@tether/angular';

@NgModule({
  declarations: [
    TetherRoot
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TetherAngularModule
  ],
  providers: [
    TetherManagerService
  ],
  bootstrap: [TetherRoot],
  entryComponents: []
})
export class TetherModule { }
