import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { TetherModule } from './app/TetherModule';
import { environment } from './environments/environment';


if (environment.production)
{
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(TetherModule)
  .catch(err => console.error(err));
