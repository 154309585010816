import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TetherManagerService } from '@tether/angular';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tether-root',
  templateUrl: './TetherRoot.html',
  styleUrls: ['./TetherRoot.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TetherRoot implements OnInit
{

  Title = 'Tether';

  constructor(public TetherManager: TetherManagerService)
  {
    console.log("Tether root started");
  }

  ngOnInit(): void
  {

  }

}
